import { Toast } from "@reframe.so/blocks";
import { ServerError } from "@src/app/errors";
import type {
  ServerAction,
  ServerActionResponse,
} from "@src/resync/mutation/server-action";

import { useRouter } from "next/navigation";
import { useState, useTransition } from "react";

export function unwrap<T>(result: ServerActionResponse<T>): T {
  if (!result.success) {
    throw ServerError.fromEvent(result.error);
  }

  return result.data;
}

export function useMutation<I extends any[], O extends any>(
  mutation: (...args: I) => Promise<O>,
  opts?: {
    refresh?: boolean;
    throw?: boolean;
    onError?: (err: unknown) => void;
    onSuccess?: (data: Awaited<O>) => void;
  }
) {
  const [pending, startTransition] = useTransition();
  const [error, setError] = useState<Error | null>(null);
  const router = useRouter();

  const mutate = (...args: I) => {
    console.log("mutate", args);

    return startTransition(async () => {
      console.log("startTransition", args);
      setError(null);
      try {
        const res = await mutation(...args);
        if (opts?.refresh !== false) {
          router.refresh();
        }
        opts?.onSuccess?.(res);
      } catch (err: any) {
        console.error(err);
        setError(err);

        if (err instanceof Error) {
          console.error(err.message);
        }

        if (opts?.onError) {
          return opts.onError(err);
        }

        if (opts?.throw) {
          throw err;
        }

        Toast.create({
          title: err.message,
          variant: "error",
        });
      }
    });
  };

  return [pending, mutate, error] as const;
}

export function useServerAction<I extends any[], O extends any>(
  action: (...args: I) => ReturnType<ServerAction<I, O>>,
  opts?: {
    refresh?: boolean;
    throw?: boolean;
    onError?: (err: unknown) => void;
    onSuccess?: (data: Awaited<O>) => void;
  }
) {
  return useMutation(async (...args: I) => {
    const result = await action(...args);

    if (!result.success) {
      throw ServerError.fromEvent(result.error);
    }

    return result.data;
  }, opts);
}

export function useFileMutation<I extends any[], O extends any>(
  mutation: (...args: I) => Promise<O>,
  opts?: {
    refresh?: boolean;
    throw?: boolean;
    onError?: (err: unknown) => void;
    onSuccess?: (data: Awaited<O>) => void;
  }
) {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const router = useRouter();

  const mutate = async (...args: I) => {
    console.log("mutate", args);
    setPending(true);
    setError(null);
    try {
      const res = await mutation(...args);
      if (opts?.refresh !== false) {
        router.refresh();
      }
      opts?.onSuccess?.(res);

      return res;
    } catch (err: any) {
      console.error(err);
      setError(err);

      if (err instanceof Error) {
        console.error(err.message);
      }

      if (opts?.onError) {
        opts.onError(err);
      }

      if (opts?.throw) {
        throw err;
      }

      Toast.create({
        title: err.message,
        variant: "error",
      });
    } finally {
      setPending(false);
    }
  };

  return [pending, mutate, error] as const;
}
